import { createContext, useContext } from "solid-js";
import type { Settings } from "~/types/common";

export const DrupalSettingsContext = createContext<Settings>();

export function useDrupalSettingsContext() {
  const context = useContext(DrupalSettingsContext);
  if (context === undefined && typeof Bun !== "undefined" && !Bun.env) {
    throw new Error(
      "useDrupalSettingsContext must be used within a DrupalSettingsContext.Provider",
    );
  }

  if (context === undefined && typeof Bun !== "undefined" && Bun?.env) {
    return useMockDrupalSettingsContext()!;
  }
  return context!;
}

let mockContext: Settings | undefined = undefined;

export function setMockDrupalSettingsContext(settings: Settings) {
  mockContext = settings;
}

export function useMockDrupalSettingsContext() {
  return mockContext;
}
